import * as React from "react"
import Button from "../components/Button"
import { Seo } from "../components/Seo"

const NotFoundPage = () => {
  return (
    <main>
      <section className="py-60">
        <div className="xl:container mx-auto px-4 text-center">
          <h1 className="font-black text-black text-2xl inline-block">
            404
          </h1>
          <p className="text-black text-3xl inline-block"><span className="mx-4">|</span> This page cannot be found</p>
          <div className="mt-8">
            <Button label="Return to homepage"
              className="text-white bg-sourci-orange"
              padding="px-10 py-2"
              type="link"
              to="/" />
          </div>
        </div>
      </section>
    </main>
  )
}

export default NotFoundPage

export const Head = () => {
  return (
    <Seo title="Not Found" />
  )
}
